import { z } from 'zod';
const getCRMViewInputSchema = z.object({
    path: z.object({
        subdomain: z.string(),
    }),
});
const getCRMViewOutputSchema = z.array(z.enum([
    'name',
    'role',
    'joinDate',
    'discordHandle',
    'discordId',
    'tiktokUsername',
    'twitterUsername',
    'twitterFollowersCount',
    'twitterId',
    'xp',
    'email',
    'country',
    'city',
    'invites',
    'address',
    'ethAddress',
]));
