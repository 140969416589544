import WAValidator from 'multicoin-address-validator';
import { match } from './patterns';
export const validateAddress = (blockchain, address) => {
    try {
        return match(blockchain, {
            ethereum: WAValidator.validate(address, 'eth'),
            polygon: WAValidator.validate(address, 'eth'),
            avalanche: WAValidator.validate(address, 'eth'),
            'binance smart chain': WAValidator.validate(address, 'bnb'),
            solana: WAValidator.validate(address, 'sol'),
            arweave: address.length === 43,
            cardano: WAValidator.validate(address, 'ada'),
        });
    }
    catch (e) {
        // test for whitespace
        return !/\s/.test(address);
    }
};
