import { z } from 'zod';
export const integrationConfigSchema = z.object({
    active: z.boolean(),
    channelId: z.string(),
    mentionId: z.string().optional(),
});
export const supportConfigSchema = z.object({
    active: z.boolean(),
    channelId: z.string(),
    feedbackChannelId: z.string().optional(),
});
export const postDiscordIntegrationInputSchema = z.object({
    newQuest: integrationConfigSchema.optional(),
    newClaim: integrationConfigSchema.optional(),
    leaderboardSprintEnd: integrationConfigSchema.optional(),
    dailySummary: integrationConfigSchema.optional(),
    support: supportConfigSchema.optional(),
});
