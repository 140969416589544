export const roleAccessLevel = Object.freeze({
    admin: 3,
    editor: 2,
    reviewer: 1,
    guest: 0,
    banned: -1,
});
export const roleIsAtLeast = (role, minimumRole) => {
    if (!role) {
        return false;
    }
    return roleAccessLevel[role] >= roleAccessLevel[minimumRole];
};
