import { z } from 'zod';
import { augmentedRewardSchema } from './admin';
import { dateTaskValueSchema, fileTaskValueSchema, numberTaskValueSchema, opinionTaskValueSchema, pollTaskValueSchema, quizTaskValueSchema, textTaskValueSchema, tweetReactTaskValueSchema, tweetTaskValueSchema, twitterSpaceTaskValueSchema, urlTaskValueSchema, } from './claimQuest';
import { baseApiTaskSchema, baseDiscordTaskSchema, baseInvitesTaskSchema, baseOnChainTaskSchema, basePartnershipTaskSchema, baseTelegramTaskSchema, baseTwitterFollowTaskSchema, baseVisitLinkTaskSchema, claimQuestStatusSchema, recurrenceSchema, } from './common';
const tweetTaskMetadataSchema = tweetTaskValueSchema.extend({
    tweetId: z.string().nullish(),
});
const tweetReactTaskMetadataSchema = tweetReactTaskValueSchema.extend({
    tweetId: z.string().nullish(),
});
export const basedTaskMetaDataSchema = z.discriminatedUnion('type', [
    basePartnershipTaskSchema,
    baseOnChainTaskSchema,
    baseApiTaskSchema,
    textTaskValueSchema,
    baseDiscordTaskSchema,
    urlTaskValueSchema,
    baseTelegramTaskSchema,
    quizTaskValueSchema,
    baseInvitesTaskSchema,
    baseVisitLinkTaskSchema,
    fileTaskValueSchema,
    dateTaskValueSchema,
    numberTaskValueSchema,
    pollTaskValueSchema,
    opinionTaskValueSchema,
    baseTwitterFollowTaskSchema,
    twitterSpaceTaskValueSchema,
    tweetReactTaskMetadataSchema,
    tweetTaskMetadataSchema,
]);
export const getClaimedQuestOutputSchema = z.object({
    id: z.string(),
    recurrence: recurrenceSchema,
    name: z.string(),
    description: z.object({}).nullish(),
    createdAt: z.string().datetime(),
    xp: z.number(),
    userId: z.string(),
    questId: z.string(),
    rewards: z.array(augmentedRewardSchema),
    status: z.enum(['pending', 'success', 'fail']),
    events: z.array(z.object({
        id: z.string(),
        createdAt: z.string().datetime(),
        claimedQuestId: z.string(),
        reviewerId: z.string(),
        type: z.union([
            z.literal('success'),
            z.literal('fail'),
            z.literal('star'),
            z.literal('flag'),
            z.literal('cancel'),
        ]),
        data: z.object({
            message: z.string().optional(),
            bonusXp: z.number().optional(),
        }),
        communityId: z.string(),
        userId: z.string(),
        user: z.object({
            name: z.string(),
        }),
    })),
    tasks: z.array(z
        .object({
        id: z.string(),
        createdAt: z.string().datetime(),
        status: claimQuestStatusSchema,
    })
        .and(basedTaskMetaDataSchema)),
    user: z.object({
        id: z.string(),
        name: z.string(),
        avatar: z.string().url().nullish(),
        discordHandle: z.string().nullish(),
        discordId: z.string().nullish(),
        twitterUsername: z.string().nullish(),
        twitterFollowers: z.number().nullish(),
        addresses: z.object({}),
    }),
});
export const getClaimedQuestsOutputSchema = z.array(getClaimedQuestOutputSchema);
