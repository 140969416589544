export const QUEST_REWARD_TYPES = ['xp', 'other', 'role', 'nft'];
export const QUEST_TYPE = ['image', 'none', 'quiz', 'text', 'twitter'];
export const QUEST_RECURRENCE = ['once', 'daily', 'weekly', 'monthly'];
export const QUEST_RETRY_TIMESTAMP_RANGE = {
    IMMEDIATELY: 0,
    ONE_MINUTE: 60,
    FIVE_MINUTES: 300,
    THIRTY_MINUTES: 1800,
    ONE_HOUR: 3600,
    ONE_DAY: 86400,
    ONE_WEEK: 604800,
    ONE_MONTH: 2592000,
    NEVER: -1,
};
