import { z } from 'zod';
import { baseRewardMethodSchema } from './common';
import { baseRewardInputSchema } from './contributor';
import { additionalQuestCheck, baseQuestInputSchema, postQuestOutputSchema, taskInputSchema, } from './postQuest';
export const patchQuestInputSchema = additionalQuestCheck(baseQuestInputSchema
    .merge(z.object({
    rewards: z.array(baseRewardInputSchema.and(z.object({
        method: baseRewardMethodSchema
            .and(z.object({
            id: z.string().uuid().optional(),
        }))
            .optional(),
    }))),
    tasks: z.array(taskInputSchema.and(z.object({
        id: z.string().uuid().optional(),
    }))),
}))
    .partial()
    .extend({
    archived: z.boolean().optional(),
})).refine(data => Object.values(data).some(v => v !== undefined), {
    message: 'One of the fields must be defined',
});
export const patchQuestOutputSchema = postQuestOutputSchema;
