import { z } from 'zod';
import { integrationConfigSchema, supportConfigSchema } from './updateIntegration';
const missingPermissionSchema = z.enum(['MISSING_ACCESS', 'SEND_MESSAGES', 'EMBED_LINKS']);
const integrationWithMissingPermissionsSchema = integrationConfigSchema.extend({
    missingPermission: missingPermissionSchema,
});
export const supportConfigSchemaWithMissingPermissions = supportConfigSchema.extend({
    missingPermission: missingPermissionSchema,
});
const webhookDataSchema = z.object({
    guildId: z.string(),
    permissions: z.number(),
    newClaim: integrationWithMissingPermissionsSchema.optional(),
    newQuest: integrationWithMissingPermissionsSchema.optional(),
    dailySummary: integrationWithMissingPermissionsSchema.optional(),
    leaderboardSprintEnd: integrationWithMissingPermissionsSchema.optional(),
    support: supportConfigSchemaWithMissingPermissions,
});
const channelSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.number(),
});
const roleSchema = z.object({
    id: z.string(),
    name: z.string(),
    position: z.number(),
    permissions: z.string(),
    editable: z.boolean(),
});
export const getGuildOutputSchema = webhookDataSchema.extend({
    id: z.string(),
    name: z.string(),
    channels: z.array(channelSchema),
    roles: z.array(roleSchema),
});
