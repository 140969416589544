import { z } from 'zod';
export const moduleSchema = z.object({
    id: z.string().uuid(),
    communityId: z.string().uuid(),
    name: z.string().max(100),
    description: z.string().max(300).optional(),
    coverUrl: z.union([z.literal('').optional(), z.string().trim().url()]),
    coverVerticalPosition: z.number().optional().default(0),
    color: z.string().optional(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deleted: z.boolean().default(false),
    position: z.number().optional(),
});
export const updateModuleInputSchema = moduleSchema.partial();
export const createModuleInputSchema = moduleSchema.pick({
    name: true,
    description: true,
    coverUrl: true,
    color: true,
    position: true,
    coverVerticalPosition: true,
});
export const patchReorderModulesInputSchema = z.object({
    moduleIds: z.array(z.string()),
});
