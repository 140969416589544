import { z } from 'zod';
const postCRMActionInputSchema = z.object({
    path: z.object({
        subdomain: z.string(),
    }),
    body: z.object({
        type: z.enum(['email', 'vote', 'role', 'xp', 'ban']),
        ids: z.array(z.string()),
        value: z.string(),
        message: z.string(),
    }),
});
const postCRMActionOutputSchema = z.object({});
