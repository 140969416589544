import { getSubdomain } from './getSubdomain';
/**
 * This function returns the URL for a specific invite
 * The URL is based on the current subdomain and the invite ID
 *
 * @param id - The invite ID
 * @returns The invite URL
 */
export const createInviteURL = (id) => {
    if (!id?.length)
        return '';
    const subdomain = getSubdomain();
    return `${process.env.NEXT_PUBLIC_APP_URL}/c/${subdomain}/invite/${id}`;
};
