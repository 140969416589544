const OLD_BASE_URL = 'c';
const NEW_BASE_URL = 'cw';
export function getCommunityPath(community) {
    if (!community || !community.subdomain) {
        return '/';
    }
    const isFeatureOn = community.v2;
    const BASE_URL = isFeatureOn ? NEW_BASE_URL : OLD_BASE_URL;
    return `/${BASE_URL}/${community.subdomain}`;
}
