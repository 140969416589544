import { z } from 'zod';
import { sprintSchema } from './getSprints';
export const postSprintsInputSchema = sprintSchema
    .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    includedQuestIds: true,
})
    .extend({
    includedQuestIds: z.array(z.string()).min(1),
});
const postSprintsOutputSchema = z.array(sprintSchema);
