import { z } from 'zod';
const getLeaderboardInputSchema = z.object({
    query: z
        .object({
        page: z.number().min(0).default(0).optional(),
        limit: z.number().min(1).optional(),
        sprint_id: z.string().uuid().optional(),
    })
        .optional(),
});
const leaderboardRecordSchema = z.object({
    userId: z.string(),
    name: z.string().nullable(),
    discord: z.string().optional(),
    address: z.string().optional(),
    xp: z.number(),
    numberOfQuests: z.number(),
    lastClaimDate: z.string().optional(),
    avatar: z.string().nullable(),
    discordId: z.string().optional(),
    twitter: z.string().optional(),
    twitterId: z.string().optional(),
});
const getLeaderboardOutputSchema = z.object({
    leaderboard: z.array(leaderboardRecordSchema),
    totalPages: z.number(),
    page: z.number(),
    totalUsers: z.number().optional(),
});
