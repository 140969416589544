export const INTERESTS = [
    'work',
    'ai',
    'gaming',
    'web3',
    'music',
    'design',
    'fashion',
    'health',
    'marketing',
    'finance',
    'engineering',
];
