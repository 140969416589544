const allBlockchains = [
    'aleph zero',
    'algorand',
    'aptos',
    'arbitrum',
    'arweave',
    'asset mantle',
    'avalanche',
    'bifrost',
    'binance smart chain',
    'cardano',
    'comdex',
    'conceal network',
    'cosmos',
    'cronos',
    'divi',
    'elrond',
    'ethereum',
    'everscale',
    'evmos',
    'exosama',
    'fantom',
    'flow',
    'fuse',
    'hedera',
    'icon',
    'immutable x',
    'iota',
    'juno',
    'kava',
    'lum network',
    'near',
    'neo',
    'okc',
    'osmosis',
    'polkadot',
    'polygon',
    'radix',
    'raptoreum',
    'reef',
    'shimmer',
    'skale',
    'solana',
    'stargate',
    'starknet',
    'sui',
    'sx network',
    'syscoin',
    'teal',
    'teritori',
    'ternoa',
    'ton',
    'line',
    'palm',
    'gnosis',
    'chiliz',
    'polygonzkevm',
    'pastel network',
    'nautilus',
    'renec',
    'kadena',
    'rootstock',
    'tagion',
    'aelf',
    'bitfinity network',
    'gurufin',
    'furya',
    'abeychain',
    'acala',
    'injective',
    'bitcoin',
    'metis',
    'moonbeam',
    'meter',
    'kyoto protocol',
    'terra',
    'tezos',
    'ultra',
    'vision chain',
    'zano',
    'zksync',
    'other',
];
// because this is called in the browser, we cannot use toSorted() yet
// export const blockchains = allBlockchains.toSorted();
// alternatively, we can sort them "manually" above so we don't need to sort them at runtime
const blockchains = [...allBlockchains];
blockchains.sort();
export { blockchains };
