import { z } from 'zod';
const patchCRMViewInputSchema = z.object({
    path: z.object({
        subdomain: z.string(),
    }),
    body: z.object({
        columns: z.array(z.string()),
    }),
});
const patchCRMViewOutputSchema = z.array(z.string());
