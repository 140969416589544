export const getXpForLevel = (level) => Math.round(300 * (level - 1) - 200);
export const getTotalXpForLevel = (lvl) => {
    const level = lvl - 1;
    return 150 * level * level - 50 * level;
};
export const getLevelFromTotalXp = (xpTotal) => {
    const a = 150;
    const b = -50;
    const c = -xpTotal;
    const discriminant = b * b - 4 * a * c;
    if (discriminant < 0) {
        return 1;
    }
    const n1 = (-b + Math.sqrt(discriminant)) / (2 * a);
    const n2 = (-b - Math.sqrt(discriminant)) / (2 * a);
    // We take the maximum value since levels are positive integers
    const level = Math.max(n1, n2);
    // We floor the value to get the current level
    return Math.floor(level) + 1;
};
export const getLevelInfo = (totalXp) => {
    const currentLevel = getLevelFromTotalXp(totalXp);
    const xpAboveLastThreshold = totalXp - getTotalXpForLevel(currentLevel);
    const xpToNextLevel = getXpForLevel(currentLevel + 1);
    return [xpAboveLastThreshold, currentLevel, xpToNextLevel];
};
